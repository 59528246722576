import 'isomorphic-fetch'
import Experiments from '@wix/wix-experiments'
import { FIELDS_ROLES, ROLE_DOWNLOAD_MESSAGE, ROLE_FORM, ROLE_MESSAGE } from '../constants/roles'
import { initBiLogger } from '../utils/bi'
import { getAppVersion, getSentryDSN, serializeError, fetcher, parseInstance } from '../utils/utils'
import { BUILDER_ORIGINS, EVENTS } from '../constants/bi'
import * as _ from 'lodash'
import * as Raven from 'raven-js'
import {
  escapeRegExp,
  innerText,
  isUploadButton,
  isCaptchaField,
  addContactsValidation,
} from './viewer-utils'
import { strategies } from './strategy/strategies'
import {
  DEFAULT_SUBMIT_ERROR,
  ERROR_TYPE_TAG_KEY,
  getAttachments,
  getFields,
  getSubmitButton,
  resetFields,
  sendFieldsToServer,
  sendWixAnalytics,
  validateFields,
} from './submit-utils'
import { getValidCollectionId } from '../editor-app/core/utils'
import { FormsFieldPreset } from '../constants/field-types'
import translations from '../utils/translations'
import { FormPlugin } from '../constants/plugins'
import { findPlugin } from '../editor-app/core/plugins/utils'
import { paymentIsAllowed } from './services/payment-services'

const ERROR_COLOR = '#FF4040'
const PREVIEW_MODE = 'Preview'
const viewerEvents = EVENTS.VIEWER_APP

Raven.config(getSentryDSN(), {
  logger: 'logger-viewer-app',
  release: getAppVersion(),
})

let initInstance
let biLogger: any = {}
let linksUtil
let viewerAppUrl
let resolveExperiments
const experimentsPromise: Promise<Experiments> = new Promise(resolve => {
  resolveExperiments = resolve
})
const getExperiments = () => experimentsPromise

const fetchTranslations = fetcher()
const loadTranslations = () => fetchTranslations.getData

export const initAppForPage = (initAppParams, payload) => {
  const { instance, url } = initAppParams
  const { links } = payload
  viewerAppUrl = url
  linksUtil = links
  initInstance = instance

  resolveExperiments(new Experiments({ baseUrl: 'https://www.wix.com', scope: 'wix-form-builder' }))
  return Promise.resolve()
}

const getVisitorId = () => initInstance && parseInstance(initInstance).aid

const getMsid = () => initInstance && parseInstance(initInstance).metaSiteId

const getFormName = $w => {
  const form = $w(`@${ROLE_FORM}`)
  return {
    form_comp_id: form.uniqueId,
    form_name: form.connectionConfig.formName,
  }
}

const paymentStatusIsValid = (status) => ['Successful','Offline','Pending'].includes(status)

const getFormParamsForBi = ($w, fields, wixLocation) => ({
  visitor_id: getVisitorId(),
  num_of_attachments: getAttachmentsCount(fields),
  form_url: wixLocation.url || '',
  ...getFormName($w),
})

const logPublishSitePopupOpened = ($w) =>
  biLogger.log({
    evid: viewerEvents.PUBLISH_SITE_PANEL_OPENED,
    form_comp_id: getFormName($w).form_comp_id,
    builder_origin: BUILDER_ORIGINS.VIEWER,
  })

const getSubmitErrorParamsForBi = ({ $w, fields, wixLocation, reason, reason_body }) => ({
  reason,
  reason_body,
  ...getFormParamsForBi($w, fields, wixLocation),
})

const getAttachmentsCount = fields =>
  _.filter(fields, field => isUploadButton(field) && field.value.length > 0).length

const getFieldValidity = fields => {
  const valueMissing = 'valueMissing'
  const errorOrder = [
    valueMissing,
    'fileNotUploaded',
    'typeMismatch',
    'patternMismatch',
    'rangeOverflow',
    'rangeUnderflow',
    'stepMismatch',
    'tooLong',
    'tooShort',
    'badInput',
    'customError',
  ]
  let errorType = _.find(errorOrder, error => _.some(fields, `validity.${error}`))
  const field = _.find(fields, field => {
    if (isCaptchaField(field)) {
      const missingToken = _.isEmpty(field.token)

      if (missingToken) {
        errorType = valueMissing
      }

      return missingToken
    }

    return _.get(field, `validity.${errorType}`)
  })

  return `${errorType} : ${_.get(field, 'connectionConfig.fieldType')}`
}

const isTemplate = wixLocation => !wixLocation.baseUrl
const isPreviewMode = wixWindow => wixWindow.viewMode === PREVIEW_MODE

const showFormError = (message, errorMessage) => {
  if (!_.get(message, 'html')) {
    return
  }
  const colorRegExp = /color: ?[^;"]+/
  let htmlErrorMessage = errorMessage
  if (message.html.indexOf(colorRegExp) === -1) {
    htmlErrorMessage = `<span style="color: ${ERROR_COLOR}">${htmlErrorMessage}</span>`
  }
  message.html = message.html
    .replace(colorRegExp, `color: ${ERROR_COLOR}`)
    .replace(new RegExp(`>${escapeRegExp(innerText(message.html))}`), `>${htmlErrorMessage}`)
  message.show()
}

const onSubmit = async (
  { $w, $message = {}, wixLocation, wixWindow, wixSite, wixPay, isPaymentForm },
  strategy
) => {
  let fields = []
  let $submitButton
  let experiments = {
    enabled: (_key: string) => false,
  }

  const postSubmitActions = (shouldShowSubmissionSuccess = true) => {
    if (shouldShowSubmissionSuccess) {
      resetFields(fields)
      strategy.postSubmission()
    }

    if (!isTemplate(wixLocation)) {
      sendWixAnalytics({ wixSite, wixLocation, wixWindow })
    }
  }

  try {
    biLogger.log({
      evid: viewerEvents.USER_CLICKS_SUBMIT,
      ...getFormParamsForBi($w, fields, wixLocation),
    })

    try {
      experiments = await getExperiments()
    } catch {}

    try {
      await loadTranslations()
    } catch (err) {
      Raven.captureMessage(err)
    }

    $submitButton = getSubmitButton($w)
    $submitButton.disable()

    fields = getFields({ $w, roles: FIELDS_ROLES })
    if (!validateFields({ fields, strategy })) {
      biLogger.log({
        evid: viewerEvents.SUBMISSION_FAILURE,
        ...getSubmitErrorParamsForBi({
          $w,
          fields,
          wixLocation,
          reason: 'field validity',
          reason_body: getFieldValidity(fields),
        }),
      })

      $submitButton.enable()

      return false
    }

    if (isPreviewMode(wixWindow) && isPaymentForm && viewerAppUrl && await paymentIsAllowed(initInstance)) {
      logPublishSitePopupOpened($w)
      const publishSitePopupUrl = () => viewerAppUrl
        .split('/')
        .slice(0, -1)
        .concat(['statics', `viewer-publish-site-panel.html`])
        .join('/')
      await wixWindow.openModal(`${publishSitePopupUrl()}?msid=${getMsid()}`, {
        width: 500, height: 247, theme: 'BARE'
      })
      $submitButton.enable()
      return false
    }

    let attachments, serverRequest

    if (!isTemplate(wixLocation) && !isPreviewMode(wixWindow)) {
      attachments = await getAttachments(fields)
      serverRequest = await sendFieldsToServer({
        strategy,
        attachments,
        fields,
        experiments,
        viewMode: wixWindow.viewMode,
      })
    } else {
      postSubmitActions()
      $submitButton.enable()
      return true
    }

    if (serverRequest && serverRequest.ok) {
      let serverResponse

      if (serverRequest.json) {
        serverResponse = await serverRequest.json()
      }

      let shouldShowSuccessMessage = true

        const orderId = _.get(serverResponse, 'orderId')
        if (orderId) {
          const userInfo = getUserInfo(fields)
          const paymentResponse = await wixPay.startPayment(orderId, { userInfo })
          if (!paymentStatusIsValid(paymentResponse.status)) {
            shouldShowSuccessMessage = false
          }
        }

      // this event should be after all server requests (wix forms + wix data)
      biLogger.log({
        evid: viewerEvents.SUBMISSION_SUCCESS, ...getFormParamsForBi($w, fields, wixLocation),
      })

      postSubmitActions(shouldShowSuccessMessage)
    } else {
      biLogger.log({
        evid: viewerEvents.SUBMISSION_FAILURE, ...getSubmitErrorParamsForBi({
          $w, fields, wixLocation, reason: 'server error', reason_body: _.get(serverRequest, 'status'),
        }),
      })
    }

    $submitButton.enable()
  } catch (err) {
    if ($submitButton) {
      $submitButton.enable()
    }

    const context = Raven.getContext()
    const reason = _.get(context, ['tags', ERROR_TYPE_TAG_KEY]) || DEFAULT_SUBMIT_ERROR

    biLogger.log({
      evid: viewerEvents.SUBMISSION_FAILURE,
      ...getSubmitErrorParamsForBi({
        $w,
        fields,
        wixLocation,
        reason,
        reason_body: err.name,
      }),
    })

    console.error(`form submit failed with: ${err}`) //eslint-disable-line no-console

    showFormError(
      $message,
      translations.t('submitFailed', {
        defaultValue: 'Something went wrong. Please try again later',
      })
    )

    Raven.captureException(err, {
      extra: {
        error: serializeError(err),
      },
    })
  }
}

const getUserInfo = fields => {
  const wantedFieldTypes = [
    FormsFieldPreset.FIRST_NAME,
    FormsFieldPreset.LAST_NAME,
    FormsFieldPreset.PHONE,
    FormsFieldPreset.EMAIL,
  ]
  const userInfo = {}

  fields.forEach(field => {
    const {
      connectionConfig: { fieldType },
    } = field
    if (!_.isEmpty(field.value) && wantedFieldTypes.includes(fieldType)) {
      userInfo[fieldType] = field.value
    }
  })

  return userInfo
}

const registerSubmitButtonIfExists = ($w, submitArgs) => {
  const $submitButton = getSubmitButton($w, false)

  // TODO: This is incorrect search for relevant strategy
  const strategy = _.find(strategies, s => s.isEnabled($w))
  if (!$submitButton || !strategy) {
    return
  }
  const strategyImp = new strategy(submitArgs, linksUtil)
  $submitButton.onClick(Raven.wrap(() => onSubmit(submitArgs, strategyImp)))
  // FIXME - Check why Raven.wrap does not catch exception (replaced throw err with captureException to overcome this for now)
}

const pageReadyImpl = ($w, payload) => {
  const {
    window: wixWindow,
    location: wixLocation,
    user: wixUsers,
    site: wixSite,
    pay: wixPay,
  } = payload

  try {
    Raven.setUserContext({ id: wixLocation.url })
    Raven.setExtraContext({ instance: initInstance })
  } catch (err) {
    Raven.captureException(err)
  }
  if (!$w(`@${ROLE_FORM}`).length) {
    return
  }
  biLogger = initBiLogger({ defaults: { msid: getMsid() } })
  const form = $w(`@${ROLE_FORM}`)
  const {
    collectionId,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    successExternalLinkValue,
    submitOptionsUploadedObject,
  } = form.connectionConfig

  const preset = _.get(form.connectionConfig, 'preset')

  if (preset) {
    Raven.setTagsContext({ preset })
  }

  const plugins = _.get(form.connectionConfig, 'plugins')
  const plugin = _.get(_.first(plugins), 'id')
  const paymentPlugin = findPlugin(plugins, FormPlugin.PAYMENT_FORM)
  const isPaymentForm = !!paymentPlugin && !!paymentPlugin.payload

  if (plugin) {
    Raven.setTagsContext({ plugin })
  }

  const locale = _.isEmpty(wixSite.language) ? 'en' : wixSite.language

  if (locale) {
    Raven.setTagsContext({ locale })
    fetchTranslations.resolveData(
      translations.init(locale, { origin: 'viewer', fallbackToDefaultValueOnException: true })
    )
  }

  const formId = form.uniqueId
  const validCollectionId = getValidCollectionId(formId, collectionId)
  const $successMessage: any = $w(`@${ROLE_MESSAGE}`)
  const $downloadMessage: any = $w(`@${ROLE_DOWNLOAD_MESSAGE}`)
  let submitArgs: any = {
    $w,
    collectionId: validCollectionId,
    secondsToResetForm,
    successActionType,
    successLinkValue,
    successExternalLinkValue,
    submitOptionsUploadedObject,
    wixLocation,
    wixWindow,
    wixUsers,
    wixSite,
    wixPay,
    isPaymentForm
  }

  if (_.get($successMessage, 'hide')) {
    $successMessage.hide()
    submitArgs = {
      ...submitArgs,
      $message: $successMessage,
      messageText: _.get($successMessage, 'html'),
    }
  }
  if (_.get($downloadMessage, 'hide')) {
    $downloadMessage.hide()
    submitArgs = {
      ...submitArgs,
      $message: $downloadMessage,
      messageText: _.get($downloadMessage, 'html'),
    }
  }
  registerSubmitButtonIfExists($w, submitArgs)
  addContactsValidation($w)
}

export const createControllers = Raven.wrap(controllerConfigs => {
  return controllerConfigs.map(() =>
    Promise.resolve({
      pageReady: Raven.wrap(pageReadyImpl),
    })
  )
})

export enum PermissionType {
  ANYONE = 'anyone',
  SITE_MEMBER = 'siteMember',
  SITE_MEMBER_AUTHOR = 'siteMemberAuthor',
  ADMIN = 'admin',
}

export type Permissions = {
  read: PermissionType
  insert: PermissionType
  update: PermissionType
  remove: PermissionType
}

export const SUBMISSION_DISPLAY_FIELD = 'submissionTime'
export const PAYMENT_DISPLAY_FIELD = 'paymentTransactionId'

export enum FieldCollectionType {
  TEXT = 'text',
  IMAGE = 'image',
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  DATETIME = 'datetime',
  RICHTEXT = 'richtext',
  COLOR = 'color',
  REFERENCE = 'reference',
  MULTI_REFERENCE = 'multi-reference',
  PAGELINK = 'pagelink',
  URL = 'url',
  DOCUMENT = 'document',
  VIDEO = 'video',
  ADDRESS = 'address',
  OBJECT = 'object',
}
